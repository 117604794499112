import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import Favicon16 from "../assets/favicon-16x16.png";
import Favicon32 from "../assets/favicon-32x32.png";
import FaviconAppleTouch from "../assets/apple-touch-icon.png";
import FaviconSafari from "../assets/safari-pinned-tab.svg";
import { langKeyDefault } from "../../buildVariables";

const SEO = ({ pageContext, location }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            supportedLanguages
          }
        }
      }
    `
  );

  const intl = useIntl();

  const lang = pageContext.langKey;
  const originalPath = location.pathname;
  const slug = lang !== langKeyDefault ? originalPath.slice(3) : originalPath;
  let title = pageContext.title;
  let metaDescription = pageContext.description || pageContext.subtitle;
  const host = site.siteMetadata.siteUrl;
  let shareImage = "tournify-share";

  if (slug === "/sports" || slug === "/sports/") {
    title = intl.formatMessage({
      id: "sports.heading",
      defaultMessage: "Sports",
    });
    metaDescription = intl.formatMessage({
      id: "sports.subheading",
      defaultMessage:
        "Tournify is designed with maximum flexibility in mind. We support over 30 different sports! This page lists a few of the most popular sports on the platform.",
    });
  }

  if (slug === "/blog" || slug === "/blog/") {
    title = intl.formatMessage({
      id: "blog.heading",
      defaultMessage: "Blog",
    });
    metaDescription = intl.formatMessage({
      id: "blog.subheading",
      defaultMessage: "Read everything that's new in our blog",
    });
  }

  if (slug === "/cases" || slug === "/cases/") {
    title = intl.formatMessage({
      id: "cases.heading",
      defaultMessage: "Cases",
    });
    metaDescription = intl.formatMessage({
      id: "cases.subheading",
      defaultMessage:
        "At Tournify, we aim to support as many tournament or league organizers as possible. Therefore, we're proud to be able to collaborate with great partners who share our passion for sports and eSports.",
    });
  }

  if (slug === "/guide" || slug === "/guide/") {
    title = intl.formatMessage({
      id: "guide.heading",
      defaultMessage: "Request the free tournament guide now",
    });
    metaDescription = intl.formatMessage({
      id: "guide.subheading",
      defaultMessage:
        "We created a guide with tips and tricks that will help you to organize the perfect (e)sports event. Request it now to get it directly in your mailbox.",
    });
  }

  var regex = /\/sports\/\w/;

  if (regex.test(slug)) {
    metaDescription = intl.formatMessage({
      id: "sports.bye_excel_description",
      defaultMessage:
        "Get rid of spreadsheets or pen and paper. Create a match schedule in minutes and turn stress into fun.",
    });
    shareImage = `tournify-share${slug
      .replace(/\/+/, "-")
      .replace(/\/+/, "-")
      .replace(/\/+/, "")}`;
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`Tournify | ${title}`}
    >
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={`Tournify: ${title}`} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:locale" content={lang} />
      <meta property="og:image" content={`${host}/share/${shareImage}.jpg`} />
      <meta name="theme-color" content="#fff" />

      <link rel="apple-touch-icon" sizes="180x180" href={FaviconAppleTouch} />
      <link rel="icon" type="image/png" href={Favicon32} sizes="32x32" />
      <link rel="icon" type="image/png" href={Favicon16} sizes="16x16" />
      <link rel="mask-icon" href={FaviconSafari} color="#ff4400" />

      <link
        rel="canonical"
        href={
          lang === langKeyDefault ? `${host}${slug}` : `${host}/${lang}${slug}`
        }
      />
      {site.siteMetadata.supportedLanguages
        .filter((l) => l !== lang && l !== langKeyDefault)
        .map((supportedLang) => (
          <link
            rel="alternate"
            hrefLang={supportedLang}
            href={`${host}/${supportedLang}${slug}`}
          />
        ))}
      {lang !== langKeyDefault && (
        <link
          rel="alternate"
          hrefLang={langKeyDefault}
          href={`${host}${slug}`}
        />
      )}
      <link rel="alternate" hrefLang="x-default" href={`${host}/en${slug}`} />

      <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
    </Helmet>
  );
};

export default SEO;
