import React, { useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Button } from "./Core";

const ChatWidgetContainer = styled.div`
  position: fixed;
  right: 1.2rem;
  bottom: 1.2rem;
  z-index: 50;
`;

const ChatWidgetBubble = styled.div`
  transform: translateY(0rem) scale(1, 1) skew(0deg, 0deg);
  display: block;
  width: 320px;
  padding: 25px;
  position: absolute;
  right: 0px;
  bottom: 5.5rem;
  background-color: #4caf50;
  border-radius: 4px;
  box-shadow: 0 2px 4px #000000;
  transform-origin: 90% 100%;
  transition: opacity 400ms ease, transform 400ms ease;
  &:after {
    content: "";
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    position: absolute;
    right: 1.2rem;
    bottom: -0.7rem;
    background-color: #4caf50;
    box-shadow: 2px 2px 2px #313131;
    transform: rotate(45deg);
  }
`;

const ChatWidgetHeader = styled.h4`
  color: #ffffff;
  size: 1.4rem;
`;

const ChatWidgetText = styled.p`
  color: #ffffff;
  margin-bottom: 1rem;
  a {
    color: #ffffff !important;
  }
`;

const ChatWidgetButton = styled.div`
  color: #ffffff;
  width: 60px;
  height: 60px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 50%;
  background-color: #4caf50;
  box-shadow: 0 0 0 -2px #000100, 0 2px 4px 0 #000000;
  transition: all 250ms ease;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 50%;
  }
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 1);
    cursor: pointer;
  }
`;

const StyledButton = styled(Button)`
  font-size: 16px !important;
  min-width: 0 !important;
  height: 45px !important;
`;

// .chat-widget--button::after {
// 	animation-delay: 0.5s;
// }

const ChatWidget = (props) => {
  const [openChat, setOpenChat] = useState(false);

  return (
    <ChatWidgetContainer>
      <ChatWidgetBubble
        style={
          openChat
            ? {
                opacity: 1,
                transform: "translateY(0rem) scale(1,1) skew(0deg,0deg)",
              }
            : {
                opacity: 0,
                transform: "translateY(3rem) scale(0, 0) skew(0deg, 50deg)",
              }
        }
      >
        <ChatWidgetHeader>
          <b>
            <FormattedMessage
              id="support.help"
              defaultMessage="How can we help you?"
            />
          </b>
        </ChatWidgetHeader>
        <ChatWidgetText>
          <FormattedMessage
            id="support.phone"
            defaultMessage="Send your question via WhatsApp to"
          />{" "}
          <a href="tel:+31202117109">+31 (0)20 211 71 09</a>.
          <FormattedMessage id="support.availability" defaultMessage=" " />
        </ChatWidgetText>

        <a
          href="https://wa.me/31202117109"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledButton size="sm" variant="outline">
            <FormattedMessage
              id="support.startChat"
              defaultMessage="Start Chat"
            />
          </StyledButton>
        </a>
      </ChatWidgetBubble>
      <ChatWidgetButton
        onClick={() => setOpenChat(!openChat)}
        style={
          openChat
            ? {
                transform: "translateY(-2px)",
                boxShadow: "0 10px 20px rgba(0, 0, 0, 1)",
              }
            : {}
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          style={{ width: 32 }}
        >
          <path
            id="whatsapp"
            d="M24.39552,7.5552A11.84119,11.84119,0,0,0,5.76286,21.83936L4.08333,27.974l6.27577-1.64631a11.82686,11.82686,0,0,0,5.65732,1.44094h.00485A11.84352,11.84352,0,0,0,24.39552,7.5552Zm-8.37425,18.214h-.004a9.82365,9.82365,0,0,1-5.00812-1.37153l-.35931-.21316-3.72411.97686.99406-3.631-.234-.37238a9.83953,9.83953,0,1,1,8.33545,4.61117Zm5.39722-7.36933c-.29582-.14806-1.75008-.86356-2.02126-.96235s-.46835-.14806-.66551.14806-.76405.96247-.93669,1.15981c-.17246.19734-.345.22215-.64081.07409a8.08074,8.08074,0,0,1-2.37877-1.46816,8.91445,8.91445,0,0,1-1.6456-2.04926c-.17252-.296-.01834-.45618.12972-.60364.13308-.1326.29583-.34552.44371-.51828a2.01944,2.01944,0,0,0,.29577-.49334.54473.54473,0,0,0-.02464-.51828c-.074-.14807-.6655-1.60412-.912-2.19649-.24013-.57666-.484-.4985-.66556-.50773-.17234-.00851-.36974-.01043-.56689-.01043a1.08675,1.08675,0,0,0-.78881.37022,3.31746,3.31746,0,0,0-1.0353,2.46767,5.75316,5.75316,0,0,0,1.20782,3.05993,13.18546,13.18546,0,0,0,5.05314,4.46658,17.00685,17.00685,0,0,0,1.68629.62306,4.05344,4.05344,0,0,0,1.8632.11713,3.04616,3.04616,0,0,0,1.99657-1.40641,2.47268,2.47268,0,0,0,.17252-1.40666C21.91148,18.622,21.71426,18.54805,21.41849,18.39987Z"
            fill="#fff"
          />
        </svg>
      </ChatWidgetButton>
    </ChatWidgetContainer>
  );
};

export default ChatWidget;
