import { createGlobalStyle } from "styled-components";
import { device } from "../utils";

const globalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.bg} !important;

    color: ${({ theme }) => theme.colors.text} !important;
  }

  p, .p{
    color: ${({ theme }) => theme.colors.text};
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.56px;
    line-height: 30px;
    margin-bottom: 0;
  }

  // ul,.ul{
  //   list-style: none;
  //   margin: 0;
  //   padding: 0;
  // }


  a {
    color: ${({ theme }) => theme.colors.text} !important;
    transition: all 0.3s ease-out;
    &:hover, &:active, &:focus{
      color: ${({ theme }) => theme.colors.secondary} !important;
      text-decoration: none!important;
      outline: none !important;
    }
  }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
    
    @keyframes slideInFromBottom {
      0% {
        transform: translateY(130%);
      }
      100% {
        transform: translateY(0);
      }
    }
    
    .cookie-consent {
      transform: translateY(130%);
      animation: 0.5s ease-out 2s 1 slideInFromBottom;
      animation-fill-mode: forwards;
      padding: 15px;
      width: calc(100% - 30px) !important;
      border-radius: 5px;
      margin: 15px;
    }

    .cookie-consent-text {
      margin: 0px 0px 15px 0px !important;
      text-align: center;
      max-width: 100%;
      flex: 1 0 328px !important;
      @media ${device.sm} {
        margin-bottom: 0px !important;
        text-align: left;
      }
    }

    .cookie-consent-buttons {
      margin-left: auto;
      margin-right: auto;
    }

    .cookie-consent-button {
      margin: 0px !important;
    }
`;

export default globalStyle;
