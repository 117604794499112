import React from "react";
import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Layout";
import { IntlProvider } from "react-intl";
import messages_en from "./src/intl/en.json";
import messages_nl from "./src/intl/nl.json";
import messages_de from "./src/intl/de.json";
import messages_es from "./src/intl/es.json";
import messages_fr from "./src/intl/fr.json";

const messages = {
  en: messages_en,
  nl: messages_nl,
  de: messages_de,
  es: messages_es,
  fr: messages_fr,
};

export const wrapPageElement = ({ element, props }) => {
  const language = props.pageContext.langKey;
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Layout {...props}>{element}</Layout>
    </IntlProvider>
  );
};

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
);
